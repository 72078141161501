const initialState = {
    user_info: {
        name: '...',
        first_name: '...',
        last_name: '...',
        profileImage: 'loading...',
        role: '...',
        email: '...',
        email_verified: 0,
        user_id: '...',
        loaded: false,
        language_code: '...',
        company_id: '',
        role_id: '',
        roleIds: [],
        showcategory_filter:0,
        showunits_certificate: 0,
        user_language: {
            code: null
        },
        company_name: null,
        currency: null,
        timezone: null,
        dateformat: null,
        timeformat: null,
        company_logo: null,
        ssi_iam_user: null,
        ssi_data_priv: null,
        phone_number:null,
        login_with_phone_number:null,
        skip:'...',
        survey:"",
        skipnow:false,
        showsurvey:false,
        default:null,
        close_call_author:null
    },
    story_feeds_available: 0
}

const user_info_reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_INFO': return {
            ...state,
            user_info: {
                name: action.payload.data.name,
                first_name: action.payload.data.first_name,
                last_name: action.payload.data.last_name,
                profileImage: 'loading...',
                role: action.payload.data.role,
                role_id: action.payload.data.role_id,
                roleIds: action.payload.data.roleIds,
                email: action.payload.data.email,
                email_verified: action.payload.data.email_verified,
                showcategory_filter: action.payload.data.showcategory_filter,
                showunits_certificate: action.payload.data.showunits_certificate,
                user_id: action.payload.data.user_id,
                language_code: action.payload.data.language_code,
                user_language: action.payload.data.user_language,
                company_id: action.payload.data.company_id,
                company_name: action.payload.data.company_name,
                currency: action.payload.data.currency,
                timezone: action.payload.data.timezone,
                dateformat: action.payload.data.dateformat,
                timeformat: action.payload.data.timeformat,
                company_logo: action.payload.data.company_logo,
                ssi_iam_user: action.payload.data.ssi_iam_user,
                ssi_data_priv: action.payload.data.ssi_data_priv,
                phone_number: action.payload.data.phone_number,
                login_with_phone_number:action.payload.data.login_with_phone_number,
                loaded: true,
                skip: action.payload.data.skip,
                survey:action.payload.data.survey,
                skipnow:action.payload.data.skipnow,
                showsurvey:action.payload.data.showsurvey,
                default:action.payload.data.default,
                close_call_author:action.payload.data.close_call_author

            }
        }

        case 'SSI_LOGOUT': {
            return {
                ...state,
                user_info: {
                    name: '...',
                    first_name: '...',
                    last_name: '...',
                    profileImage: 'loading...',
                    role: '...',
                    email: '...',
                    user_id: '...',
                    loaded: false,
                    language_code: '...',
                    company_id: '',
                    role_id: '',
                    user_language: {
                        code: null
                    },
                    company_name: null,
                    currency: '...',
                    timezone: '...',
                    dateformat: '...',
                    timeformat: '...',
                    company_logo: '...',
                    ssi_iam_user: '...',
                    ssi_data_priv: '...',
                    phone_number:'...',
                    login_with_phone_number:'...',
                    skip:'...',
                    survey:'...',
                    skipnow:false,
                    showsurvey:false,
                    default:null,
                    close_call_author:null

                }
            }
        }
        case 'STORYFEED_AVAILABLE':
            console.log('STORYFEED Available--',action.payload.data);
            return {
            ...state,
            story_feeds_available: action.payload.data
        }

        default: {
            if (state.user_info.loaded === true) {
                return state
            } else {
                return initialState
            }
        }
    }

}

export default user_info_reducer;
